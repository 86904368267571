export default {
  searchDate: 0,
  mediaTypes: {
    feed: {
      description: "Você está pesquisando todos tipos de mídia",
      icon: "mdi-rss",
      types: ["Feed"],
      route: "feed",
    },
    alerta: {
      description: "Você está pesquisando alerta",
      icon: "mdi-bell-ring-outline",
      types: ["Alerta"],
      route: "alerta",
    },
    noticia: {
      description: "Você está pesquisando notícia",
      icon: "mdi-newspaper",
      types: ["Notícia"],
      route: "noticia",
    },
    estudo: {
      description: "Você está pesquisando estudo",
      icon: "mdi-chart-areaspline",
      types: ["Estudo"],
      route: "estudo",
    },
    comunicacao: {
      description: "Você está pesquisando comunicação",
      icon: "mdi-folder-multiple-image",
      types: ["Comunicação"],
      route: "comunicacao",
    },
    socials: {
      description: "Você está pesquisando redes sociais",
      icon: "mdi-television-play",
      types: ["Post de uma rede social"],
      route: "socials",
    },
    "alerta-noticia": {
      description: "Você está procurando alertas e noticias",
      icon: "mdi-rss",
      types: ["Alerta", "Notícia"],
      route: "alerta-noticia",
    },
    "*": {
      description: "Você está pesquisando todos os tipos de mídias",
      icon: "mdi-rss",
      types: ["*"],
      route: "*",
    },
  },
};
