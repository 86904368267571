import Http from "@/plugins/http";

export const getFiles = (accountId, page = 1, params = {}) => {
  return Http.get(`storage/${accountId}/files`, {
    params: {
      ...params,
      page,
    },
  });
};

export const getFile = (id, accountId) => {
  return Http.get(`storage/${accountId}/files/${id}`);
};

export const uploadFiles = (accountId, formData, args = {}) => {
  return Http.post(`storage/${accountId}/files`, formData, {
    args,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadUrl = (accountId, params) => {
  return Http.post(`storage/${accountId}/files/url`, params);
};

export const updateFile = (accountId, id, params) => {
  return Http.put(`storage/${accountId}/files/${id}`, params);
};

export const deleteFile = (accountId, id) => {
  return Http.delete(`storage/${accountId}/files/${id}`);
};

export const downloadFiles = (accountId, filesId) => {
  return Http.get(`storage/${accountId}/files/download`, {
    responseType: "blob",
    params: { filesId },
  });
};

export const findByHash = (hash, downloadUrl = false) => {
  return Http.get(`storage/files/share/${hash}`, {
    params: {
      downloadUrl,
    },
  });
};

export const enableShare = (accountId, id, params) => {
  return Http.put(`storage/${accountId}/files/share/${id}/enable`, params);
};

export const disableShare = (accountId, id, params) => {
  return Http.put(`storage/${accountId}/files/share/${id}/disable`, params);
};
