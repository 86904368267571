export default {
  async getTypeByRoute(context, type = "feed") {
    try {
      if (Array.isArray(type)) {
        const plural = type.length > 1 ? "dos tipos" : "do tipo";
        return {
          description: `Pesquisa ${plural}: ${type.join(",")}`,
          icon: "mdi-magnify",
          types: type,
          route: "*",
        };
      }
      const availableTypes = context.getters.mediaTypes;

      if (availableTypes[type.toLowerCase()]) {
        return availableTypes[type.toLowerCase()];
      }

      const typeByValue = Object.keys(availableTypes).find((mediaType) => {
        const currentType = availableTypes[mediaType];
        if (currentType.types.length === 1 && currentType.types.includes(type)) {
          return true;
        }
        return false;
      });

      if (availableTypes[typeByValue]){
        return availableTypes[typeByValue];
      }

      return availableTypes.feed;
    } catch (error) {
      return context.getters.mediaTypes.feed
    }
  },

  async getTypeByText(context, type) {
    return Object.values(context.getters.mediaTypes).find((mediaType) =>
      mediaType.types.includes(type),
    );
  },
};
