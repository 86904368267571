import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/storage",
    name: "storage.view",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Storage.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Armazenamento",
    },
  },
  {
    path: "/storage/galery",
    name: "galery.view",
    component: () => import(/* webpackChunkName: "galery" */ "../views/Galery.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Galeria",
    },
  },
  {
    path: "/storage/galery/:id",
    name: "galery.view.item",
    component: () => import(/* webpackChunkName: "galery" */ "../views/FileView.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Galeria",
      containerStyle: {
        height: '100%'
      }
    },
  },
  {
    path: "/storage/folder/:id",
    name: "folder.view",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Folder.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Pasta",
    },
  },
  {
    path: "/storage/folder/shared/:hash",
    name: "folder.shared.view",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Folder.vue"),
    props: {
      shared: true,
    },
    meta: {
      middleware: [AuthMiddleware],
      title: "Pasta compartilhada",
    },
  },
  {
    path: "/storage/file/shared/:hash",
    name: "file.shared.view",
    component: () => import(/* webpackChunkName: "feed" */ "../views/SharedFile.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Arquivo compartilhado",
      layout: "blank",
    },
  },
];
