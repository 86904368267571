import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/rss",
    name: "rss.view",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Rss.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Feed RSS",
      guards: ["acl"],
      slug: "rss.view",
      showSearchBar: false
    },
  },
  {
    path: "/rss/management",
    name: "rss.management",
    component: () => import("../views/RssManagement"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Feed RSS",
      guards: ["acl"],
      slug: "rss.view",
    },
  },
];
