import { getAccountDrawer } from "@/modules/config/services/DrawerService.js";
import store from "@/store";

export default {
  async setDrawer(context) {
    await getAccountDrawer(store.state.auth.user.account_id)
      .then((response) => {
        context.commit("setNavigationDrawer", response.data);
      })
      .catch(() => {});
  },

  getActiveDrawerItem(context, router) {
    const item = context.state.navigationDrawer?.items?.find((drawerItem) => {
      return router.resolve(drawerItem.route).route.fullPath === router.resolve(router.currentRoute).route.fullPath;
    });
    return item;
  },
};
